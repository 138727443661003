#wrapper {
  /* width: 100%; */
  /* height: 75px; */
  /* background-color: green; */
  text-align: center;
}
#button {
  /* height: 40px; */
  /* width: 25%; */
  margin: auto;
  /* background-color: yellow; */
  top: 25px;
  /* position: absolute; */
  padding: 20;
}
#drop {
  display: none;
  background-color: white;
  position: absolute;
  width: 340px;
  text-align: left;
}

#dropMobile {
  display: none;
  background-color: white;
  /* position: absolute; */
  width: 340px;
  text-align: left;
}

#dropMobileFAQ {
  display: none;
  background-color: white;
  /* position: absolute; */
  text-align: left;
}
#button:hover #drop {
  display: block;
}

#button:hover #dropMobile {
  display: flex;
  flex-direction: column;
}

#button:hover #dropMobileFAQ {
  display: flex;
  flex-direction: column;
}

.faqClass {
  background-color: '#15AE81';
}
.faqClass:hover {
  background-color: white;

}
